import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";

const Catalogue = function ({
  data: {
    appSetting,
    page = {
      title: "Catalogue",
    },
  },
}) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={page.hero?.title ? page.hero.title : page.title}
        description={page.hero?.description && page.hero.description}
        background={
          page.hero?.background && page.hero.background.localFile.publicURL
        }
      />
      <section className="ttm-row-page">
        <Container>
          <h2>SOONER MUSIC - POP &amp; ROCK</h2>
          <iframe
            className="mb-5"
            title="SOONER MUSIC - POP &amp; ROCK"
            width="100%"
            height="450"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1493938987%3Fsecret_token%3Ds-0U80Jpzy41m&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          ></iframe>
          <h2>SOONER MUSIC - POP &amp; GROOVE</h2>
          <iframe
            className="mb-5"
            title="SOONER MUSIC - POP &amp; GROOVE"
            width="100%"
            height="450"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1493938576%3Fsecret_token%3Ds-ITFX2PEL9Ws&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          ></iframe>
          <h2>SOONER MUSIC - ELECTRO &amp; HOUSE</h2>
          <iframe
            title="SOONER MUSIC - ELECTRO &amp; HOUSE"
            width="100%"
            height="450"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1493938231%3Fsecret_token%3Ds-T6bcYFTol4s&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          ></iframe>
        </Container>
      </section>
    </Layout>
  );
};

Catalogue.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Catalogue;

export const Head = ({ location }) => (
  <Seo title="Catalogue" noRobots pathname={location.pathname} />
);

export const pageQuery = graphql`
  query {
    appSetting: strapiAppSetting(locale: { eq: "fr" }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
