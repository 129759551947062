import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "react-bootstrap";

import * as s from "./Hero.module.scss";

const Hero = function ({ title, description, background }) {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { regex: "/hero_default/" }) {
          publicURL
        }
      }
    `
  );

  const bg = background || file?.publicURL;

  return (
    <div
      className={s.root}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <Container>
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </Container>
    </div>
  );
};

Hero.defaultProps = {
  description: ``,
  background: ``,
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  background: PropTypes.string,
};

export default Hero;
